<template>
    <div>
        <el-dialog
            v-model="failVisible"
            width="20%"
            title="审核失败原因"
            :before-close="handleClose"
            @close="close"
            @open="open"
        >
            <div>
                <el-input
                    v-model="textarea"
                    :rows="2"
                    type="textarea"
                    disabled
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            failVisible:false,
            textarea:''
        }
    },
    methods:{
        open(rows) {
            this.failVisible = true;
            this.textarea = rows.fail_reason;
        }
    }
}
</script>